import fetch from "unfetch";
import {checkStatus} from "../resources/ApiUtils";
import UserService from "../users/UserService";

const userService = new UserService();


export default class RoutingRulesService {

    getRoutingRuleById(id){

        const apiKey = userService.getApiKey();

        const requestOptions = {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        };

        let pathVariable = `/api/v1/routing-rules/${id}`;

        return fetch(pathVariable, requestOptions)
            .then(checkStatus)
            .then(response => response.json());
    }


    getRoutingRulesByParams(params) {

        const apiKey = userService.getApiKey();

        let queryString = ``;

        if (params) {

            const keys = Object.keys(params);
            for (let i = 0; i < keys.length; i++) {
                const key = keys[i];
                const value = params[key];

                let filterString = key.toString();
                if (queryString.length > 0) {
                    queryString += `&`;
                }
                queryString += `${filterString}=${value}`;
            }
        }

        const requestOptions = {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        };

        let pathVariable = `/api/v1/routing-rules`;
        if (queryString.length > 0) {
            pathVariable += `?` + queryString
        }
        return fetch(pathVariable, requestOptions)
            .then(checkStatus)
            .then(response => response.json());
    }

    createRoutingRule(inputDto) {

        const apiKey = userService.getApiKey();

        const requestOptions = {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            body: JSON.stringify(inputDto)
        };

        let pathVariable = `/api/v1/routing-rules`;

        return fetch(pathVariable, requestOptions)
            .then(checkStatus)
    }

    deleteRoutingRule(routingRuleId) {

        const apiKey = userService.getApiKey();

        const requestOptions = {
            method: 'DELETE',
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        };

        let pathVariable = `/api/v1/routing-rules/${routingRuleId}`;

        return fetch(pathVariable, requestOptions)
            .then(checkStatus)
    }

    updateRoutingRule(routingRuleId, inputDto){

        const apiKey = userService.getApiKey();

        const requestOptions = {
            method: 'PUT',
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            body: JSON.stringify(inputDto)
        };

        let pathVariable = `/api/v1/routing-rules/${routingRuleId}`;

        return fetch(pathVariable, requestOptions)
            .then(checkStatus)

    }


}