import {checkStatus} from "../resources/ApiUtils";
import fetch from 'unfetch';


export default class UserService {
    isLoggedIn() {
        const apiKey = localStorage.getItem("bridgeApiKey") || null;
        return apiKey ? true : false;
    }

    getApiKey() {
        return localStorage.getItem("bridgeApiKey");
    }

    login(username, password) {
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            body: JSON.stringify({username: username, password: password})
        };

        return fetch("/api/v1/users/login", requestOptions)
            .then(checkStatus)
            .then(response => response.json())
            .then((response) => {
                const apiKey = response.apiKey;
                localStorage.setItem("bridgeApiKey", apiKey);
            });
    }

    logout() {
        localStorage.removeItem("bridgeApiKey");
    }


}