import {useEffect, useState} from "react";
import {
    Autocomplete,
    Button,
    CircularProgress,
    DialogTitle,
    Grid,
    InputAdornment,
    Paper,
    TextField,
    Typography
} from "@mui/material";
import GeoService from "../geo/GeoService";
import * as React from "react";
import {useTranslation} from "react-i18next";
import {useNavigate, useParams} from "react-router-dom";
import {ArrowBack, CloseOutlined, SaveOutlined, Search} from "@mui/icons-material";
import theme from "../resources/theme";
import SorterService from "../sorters/SorterService";
import SortingModeService from "../sorting_modes/SortingModeService";
import GateService from "../gates/GateService";
import RoutingRulesService from "./RoutingRulesService";
import Notification from "../resources/Notification";

export default function RoutingRuleForm(props) {

    const {id} = useParams();

    const [sorter, setSorter] = useState(null);
    const [sortingMode, setSortingMode] = useState(null);
    const [contractCode, setContractCode] = useState('');
    const [accountCorporateCode, setAccountCorporateCode] = useState('');
    const [country, setCountry] = useState(null);
    const [administrativeLevel1, setAdministrativeLevel1] = useState(null);
    const [administrativeLevel2, setAdministrativeLevel2] = useState(null);
    const [administrativeLevel3, setAdministrativeLevel3] = useState(null);
    const [city, setCity] = useState(null);
    const [postalCode, setPostalCode] = useState(null);
    const [hamlet, setHamlet] = useState(null);
    const [selectedGates, setSelectedGates] = useState([]);

    const [minWeight, setMinWeight] = useState('');
    const [maxWeight, setMaxWeight] = useState('');
    const [minVolume, setMinVolume] = useState('');
    const [maxVolume, setMaxVolume] = useState('');

    const [warehouseCode, setWarehouseCode] = useState('');
    const [zoneCode, setZoneCode] = useState('');

    const [sorters, setSorters] = useState([]);
    const [sortingModes, setSortingModes] = useState([]);
    const [countries, setCountries] = useState([]);
    const [administrativeLevel1List, setAdministrativeLevel1List] = useState([]);
    const [administrativeLevel2List, setAdministrativeLevel2List] = useState([]);
    const [administrativeLevel3List, setAdministrativeLevel3List] = useState([]);
    const [cities, setCities] = useState([]);
    const [postalCodes, setPostalCodes] = useState([]);
    const [hamlets, setHamlets] = useState([]);
    const [gates, setGates] = useState([]);

    const [loading, setLoading] = useState(false);

    const [notificationMessage, setNotificationMessage] = useState('');
    const [notificationSeverity, setNotificationSeverity] = useState('');
    const [openNotification, setOpenNotification] = useState(false);

    function closeNotification() {
        setOpenNotification(false);
    }

    const {t} = useTranslation();
    const navigate = useNavigate();

    async function fetchGates() {

        let params = {};

        if (sorter) {
            params.sorterID = sorter.id;
        }

        await new GateService().getGates(params)
            .then(result => {
                setGates(result);
            })
            .catch(error => {
                error.response.json().then(response => {
                    setNotificationMessage(response.message);
                    setNotificationSeverity('error');
                    setOpenNotification(true);
                })
            })

    }


    async function fetchSorters() {

        await new SorterService().getSorters()
            .then(result => {
                setSorters(result)
            })
            .catch(error => {
                error.response.json().then(response => {
                    setNotificationMessage(response.message);
                    setNotificationSeverity('error');
                    setOpenNotification(true);
                })
            })

    }


    async function fetchSortingModes() {

        await new SortingModeService().getSortingModes({enabledOnly: true})
            .then(result => {
                setSortingModes(result)
            })
            .catch(error => {
                error.response.json().then(response => {
                    setNotificationMessage(response.message);
                    setNotificationSeverity('error');
                    setOpenNotification(true);
                })
            })

    }


    async function fetchCountries() {

        await new GeoService().getCountries()
            .then(result => {
                setCountries(result);
            })
            .catch(error => {
                error.response.json().then(response => {
                    setNotificationMessage(response.message);
                    setNotificationSeverity('error');
                    setOpenNotification(true);
                })
            })

    }

    async function fetchAdministrativeLevel1() {

        let params = {};

        if (country) {
            params.countryID = country.id;
        }

        await new GeoService().getAdministrativeLevel1(params)
            .then(response => {
                setAdministrativeLevel1List(response);
            })
            .catch(error => {
                error.response.json().then(response => {
                    setNotificationMessage(response.message);
                    setNotificationSeverity('error');
                    setOpenNotification(true);
                })
            })

    }

    async function fetchAdministrativeLevel2() {

        let params = {};

        if (administrativeLevel1) {
            params.administrativeLevel1ID = administrativeLevel1.id;
        }

        await new GeoService().getAdministrativeLevel2(params)
            .then(response => {
                setAdministrativeLevel2List(response);
            })
            .catch(error => {
                error.response.json().then(response => {
                    setNotificationMessage(response.message);
                    setNotificationSeverity('error');
                    setOpenNotification(true);
                })
            })

    }

    async function fetchAdministrativeLevel3() {

        let params = {};

        if (administrativeLevel2) {
            params.administrativeLevel2ID = administrativeLevel2.id;
        }

        await new GeoService().getAdministrativeLevel3(params)
            .then(response => {
                setAdministrativeLevel3List(response);
            })
            .catch(error => {
                error.response.json().then(response => {
                    setNotificationMessage(response.message);
                    setNotificationSeverity('error');
                    setOpenNotification(true);
                })
            })

    }


    async function fetchCities() {

        let params = {};

        if (administrativeLevel3) {
            params.administrativeLevel3ID = administrativeLevel3.id;
        }

        await new GeoService().getCities(params)
            .then(response => {
                setCities(response);
            })
            .catch(error => {
                error.response.json().then(response => {
                    setNotificationMessage(response.message);
                    setNotificationSeverity('error');
                    setOpenNotification(true);
                })
            })

    }

    async function fetchPostalCodes() {

        let params = {};

        if (city) {
            params.cityID = city.id;
        }

        if (administrativeLevel3) {
            params.administrativeLevel3ID = administrativeLevel3.id;
        }

        await new GeoService().getPostalCodes(params)
            .then(response => {
                setPostalCodes(response);
            })
            .catch(error => {
                error.response.json().then(response => {
                    setNotificationMessage(response.message);
                    setNotificationSeverity('error');
                    setOpenNotification(true);
                })
            })

    }

    function handleCountryChange(event, country) {

        setCountry(country);
        setAdministrativeLevel1(null);
        setAdministrativeLevel2(null);
        setAdministrativeLevel3(null);
        setCity(null);
        setPostalCode(null);
        setHamlet(null);

    }

    function handleAdministrativeLevel1Change(event, administrativeLevel1) {

        setAdministrativeLevel1(administrativeLevel1);
        setAdministrativeLevel2(null);
        setAdministrativeLevel3(null);
        setCity(null);
        setPostalCode(null);
        setHamlet(null);

    }

    function handleAdministrativeLevel2Change(event, administrativeLevel2) {


        setAdministrativeLevel2(administrativeLevel2);
        setAdministrativeLevel3(null);
        setCity(null);
        setPostalCode(null);
        setHamlet(null);

    }

    function handleAdministrativeLevel3Change(event, administrativeLevel3) {


        setAdministrativeLevel3(administrativeLevel3);
        setCity(null);
        setPostalCode(null);
        setHamlet(null);

    }

    function handleCityChange(event, city) {

        setCity(city);
        setPostalCode(null);
        setHamlet(null);

    }

    function handlePostalCodeChange(event, postalCode) {

        setPostalCode(postalCode);
        setHamlet(null);

    }

    function handleSorterChange(event, newSorter) {
        if (newSorter !== sorter) {
            setSelectedGates([]);
        }
        setSorter(newSorter);
    }

    function handleSortingModeChange(event, sortingMode) {
        setSortingMode(sortingMode);
    }


    function handleGatesChange(event, gates) {
        setSelectedGates(gates);
    }

    function handleAccountCorporateCodeChange(event) {
        setAccountCorporateCode(event.target.value);
    }

    function handleContractCodeChange(event) {
        setContractCode(event.target.value);
    }

    function handleMinWeightChange(event) {
        setMinWeight(event.target.value);
    }

    function handleMaxWeightChange(event) {
        setMaxWeight(event.target.value);
    }

    function handleMinVolumeChange(event) {
        if (event.target.value > 0 || event.target.value === '') {
            setMinVolume(event.target.value);
        }
        if (maxVolume !== '' && event.target.value > maxVolume) {
            setMaxVolume(event.target.value);
        }
    }

    function handleMaxVolumeChange(event) {
        if (event.target.value > 0 || event.target.value === '') {
            setMaxVolume(event.target.value);
        }
        if (minVolume !== '' && event.target.value < minVolume) {
            setMinVolume(event.target.value);
        }
    }


    async function save(close) {

        let input = {};

        if (sorter) {
            input.sorterId = sorter.id;
        }
        if (sortingMode) {
            input.sortingModeId = sortingMode.id;
        }
        if ('' !== accountCorporateCode) {
            input.accountCorporateCode = accountCorporateCode;
        }
        if ('' !== contractCode) {
            input.contractCode = contractCode;
        }
        if ('' !== warehouseCode) {
            input.warehouseCode = warehouseCode;
        }
        if ('' !== zoneCode) {
            input.zoneCode = zoneCode;
        }
        if (country) {
            input.country = country.iso3166Alpha3;
        }
        if (administrativeLevel1) {
            input.administrativeLevel1 = administrativeLevel1.name;
        }
        if (administrativeLevel2) {
            input.administrativeLevel2 = administrativeLevel2.name;
        }
        if (administrativeLevel3) {
            input.administrativeLevel3 = administrativeLevel3.name;
        }
        if (city) {
            input.city = city.name;
        }
        if (postalCode) {
            input.postalCode = postalCode.postalCode;
        }
        if (hamlet) {
            input.hamlet = hamlet.name;
        }

        if (minWeight && minWeight !== '') {
            input.minWeight = minWeight;
        }
        if (maxWeight && maxWeight !== '') {
            input.maxWeight = maxWeight;
        }
        if (minVolume && minVolume !== '') {
            input.minVolume = minVolume;
        }
        if (maxVolume && maxVolume !== '') {
            input.maxVolume = maxVolume;
        }

        let inputGates = [];

        for (let i = 0; i < selectedGates.length; i++) {

            inputGates.push(selectedGates[i].number);

        }

        input.gates = inputGates;


        if (id) {
            await new RoutingRulesService().updateRoutingRule(id, input)
                .then(result => {
                    setNotificationMessage(t("operationSuccessful"));
                    setNotificationSeverity('success');
                    setOpenNotification(true);

                    if (close) {
                        navigate('/routing-rules')
                    }
                })
                .catch(error => {
                    error.response.json().then(response => {
                        setNotificationMessage(response.message);
                        setNotificationSeverity('error');
                        setOpenNotification(true);
                    })
                })
        } else {
            await new RoutingRulesService().createRoutingRule(input)
                .then(result => {
                    setNotificationMessage(t("operationSuccessful"));
                    setNotificationSeverity('success');
                    setOpenNotification(true);

                    if (close) {
                        navigate('/routing-rules')
                    }
                })
                .catch(error => {
                    error.response.json().then(response => {
                        setNotificationMessage(response.message);
                        setNotificationSeverity('error');
                        setOpenNotification(true);
                    })
                })
        }


    }

    async function fetchRoutingRule() {

        setLoading(true);

        await new RoutingRulesService().getRoutingRuleById(id)
            .then(async result => {
                await setUpFields(result);
            })
            .catch(error => {

                setLoading(false);
            })


    }

    async function setUpFields(rule) {

        setAccountCorporateCode(rule.accountCorporateCode);
        setContractCode(rule.contractCode);
        setWarehouseCode(rule.warehouseCode);
        setZoneCode(rule.zoneCode);

        setMinWeight(rule.minWeight);
        setMaxWeight(rule.maxWeight);
        setMinVolume(rule.minVolume);
        setMaxVolume(rule.maxVolume);

        await new SorterService().getSorters()
            .then(async result => {
                let sorter = result.find(s => s.name === rule.sorter);
                setSorter(sorter);

                //gates

                let existingGates = [];

                let params = {};
                if (sorter) {
                    params.sorterID = sorter.id;
                }
                await new GateService().getGates(params)
                    .then(async result => {
                        for (let i = 0; i < rule.gates.length; i++) {

                            let gateFound = result.find(gate => gate.number === rule.gates[i]);

                            existingGates.push(gateFound);

                        }
                    })
                    .catch(error => {

                    })

                setSelectedGates(existingGates);
            })
            .catch(error => {

            })


        //sortingMode
        await new SortingModeService().getSortingModes()
            .then(async result => {
                await setSortingMode(result.find(smode => smode.name === rule.sortingMode));
            })
            .catch(error => {

            })
        //address


        if (rule.country) {
            await new GeoService().getCountries()
                .then(async result => {
                    let country = result.find(coun => coun.iso3166Alpha2 === rule.country);
                    setCountry(country);

                    if (rule.administrativeLevel1) {
                        await new GeoService().getAdministrativeLevel1({countryID: country.id})
                            .then(async result => {
                                let admin1 = result.find(admin1 => admin1.name === rule.administrativeLevel1);
                                setAdministrativeLevel1(admin1);

                                if (rule.administrativeLevel2) {
                                    await new GeoService().getAdministrativeLevel2({administrativeLevel1ID: admin1.id})
                                        .then(async result => {
                                            let admin2 = result.find(admin2 => admin2.name === rule.administrativeLevel2);
                                            setAdministrativeLevel2(admin2);

                                            if (rule.administrativeLevel3) {
                                                await new GeoService().getAdministrativeLevel3({administrativeLevel2ID: admin2.id})
                                                    .then(async result => {
                                                        let admin3 = result.find(admin3 => admin3.name === rule.administrativeLevel3);
                                                        setAdministrativeLevel3(admin3);

                                                        if (rule.city) {
                                                            await new GeoService().getCities({administrativeLevel3ID: admin3.id})
                                                                .then(async result => {
                                                                    let city = result.find(city => city.name === rule.city);
                                                                    setCity(city);

                                                                    if (rule.postalCode) {
                                                                        await new GeoService().getPostalCodes({cityID: city.id})
                                                                            .then(result => {
                                                                                let postalCode = result.find(pcode => pcode.postalCode === rule.postalCode);
                                                                                setPostalCode(postalCode);
                                                                            })
                                                                            .catch(error => {

                                                                            })
                                                                    }
                                                                })
                                                                .catch(error => {

                                                                })
                                                        }
                                                    })
                                                    .catch(error => {

                                                    })
                                            }
                                        })
                                        .catch(error => {

                                        })
                                }
                            })
                            .catch(error => {

                            })
                    }
                })
                .catch(error => {

                })
        }

        setLoading(false);

    }

    async function saveAndClose() {

        await save(true);

    }

    function goBack() {

        navigate('/routing-rules');

    }

    async function setUpSorter() {

        setLoading(true);

        let sorters = await new SorterService().getSorters()
            .catch(error => {

            })

        if (sorters.length === 1) {
            await setSorter(sorters[0]);
        }

        setLoading(false);

    }

    useEffect(() => {

        if (id) {
            fetchRoutingRule();
        } else {
            setUpSorter();
        }
    }, [])

    return <div>
        <Notification
            message={notificationMessage}
            open={openNotification}
            onClose={closeNotification}
            severity={notificationSeverity}
            key={notificationSeverity}
        />
        {loading ?
            <Paper>
                <div style={{display: "flex", marginBlock: "10%", paddingBlock: "10%"}}>
                    <CircularProgress size={50} style={{margin: "auto"}}/>
                </div>
            </Paper>
            :
            <Paper>
                <div style={{display: "flex"}}>
                    <Button onClick={goBack} startIcon={<ArrowBack/>} style={{
                        margin: "auto",
                        marginBlock: "2%",
                        backgroundColor: '#969696',
                        color: "white"
                    }}>{t("goBack")}</Button>
                </div>

                <div style={{display: "flex"}}>
                    <div style={{width: "50%"}} id={"sorter_section"}>
                        <DialogTitle style={{width: "fit-content", margin: "auto"}}>{t("sortingData")}</DialogTitle>
                        <div style={{display: "flex"}}>
                            <Autocomplete
                                style={{margin: "auto", width: "40%"}}
                                options={sorters}
                                getOptionLabel={option => option.name}
                                value={sorter}
                                defaultValue={sorter}
                                onOpen={fetchSorters}
                                onChange={handleSorterChange}
                                loading={true}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label={t("sorter")}
                                        variant="outlined"
                                        margin="dense"
                                        value={sorter}
                                        defaultValue={sorter}
                                        inputProps={{
                                            ...params.inputProps,
                                            autoComplete: 'off', // disable autocomplete and autofill
                                        }}
                                    />
                                )}
                            />
                            <Autocomplete
                                style={{margin: "auto", width: "40%"}}
                                options={sortingModes}
                                getOptionLabel={option => option.name}
                                value={sortingMode}
                                defaultValue={sortingMode}
                                onOpen={fetchSortingModes}
                                onChange={handleSortingModeChange}
                                loading={true}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label={t("sortingMode")}
                                        variant="outlined"
                                        margin="dense"
                                        value={sortingMode}
                                        defaultValue={sortingMode}
                                        inputProps={{
                                            ...params.inputProps,
                                            autoComplete: 'off', // disable autocomplete and autofill
                                        }}
                                    />
                                )}
                            />
                        </div>
                        <div>
                            <div style={{width: "100%", display: "flex", marginBlock: "2%"}}>
                                <TextField
                                    style={{margin: "auto", width: "40%"}}
                                    id="account"
                                    label={t("accountCorporateCode")}
                                    value={accountCorporateCode}
                                    onChange={(event) => {
                                        setAccountCorporateCode(event.target.value);
                                    }}
                                />
                                <TextField
                                    style={{margin: "auto", width: "40%"}}
                                    id="contract"
                                    label={t("contractCode")}
                                    value={contractCode}
                                    onChange={(event) => {
                                        setContractCode(event.target.value);
                                    }}
                                />
                            </div>
                            <div style={{width: "100%", display: "flex", marginBottom: "1%"}}>
                                <TextField
                                    style={{margin: "auto", width: "40%"}}
                                    id="warehouse"
                                    label={t("warehouseCode")}
                                    value={warehouseCode}
                                    onChange={(event) => {
                                        setWarehouseCode(event.target.value);
                                    }}
                                />
                                <TextField
                                    style={{margin: "auto", width: "40%"}}
                                    id="zone"
                                    label={t("zoneCode")}
                                    value={zoneCode}
                                    onChange={(event) => {
                                        setZoneCode(event.target.value);
                                    }}
                                />
                            </div>
                        </div>
                        <div style={{width: "100%", display: "flex", marginBlock: "2%"}}>
                            <TextField
                                style={{margin: "auto", width: "40%"}}
                                id="minWeight"
                                label={t("minWeight")}
                                type={"number"}
                                value={minWeight}
                                inputProps={{
                                    startAdornment: <InputAdornment position="start">Kg</InputAdornment>,
                                    maxLength: 5,
                                    step: 0.1,

                                }}
                                onChange={handleMinWeightChange}
                            />

                            <TextField
                                style={{margin: "auto", width: "40%"}}
                                id="maxWeight"
                                label={t("maxWeight")}
                                type={"number"}
                                value={maxWeight}
                                inputProps={{
                                    startAdornment: <InputAdornment position="start">Kg</InputAdornment>,
                                    maxLength: 5,
                                    step: 0.1,

                                }}
                                onChange={handleMaxWeightChange}
                            />
                        </div>
                        <div style={{width: "100%", display: "flex", marginBlock: "1%"}}>
                            <TextField
                                style={{margin: "auto", width: "40%"}}
                                id="minVolume"
                                label={t("minVolume")}
                                type={"number"}
                                value={minVolume}
                                inputProps={{
                                    startAdornment: <InputAdornment position="start">Kg</InputAdornment>,
                                    maxLength: 5,
                                    step: 0.1,

                                }}
                                onChange={handleMinVolumeChange}
                            />

                            <TextField
                                style={{margin: "auto", width: "40%"}}
                                id="maxVolume"
                                label={t("maxVolume")}
                                type={"number"}
                                value={maxVolume}
                                inputProps={{
                                    startAdornment: <InputAdornment position="start">Kg</InputAdornment>,
                                    maxLength: 5,
                                    step: 0.1,

                                }}
                                onChange={handleMaxVolumeChange}
                            />
                        </div>
                    </div>
                    <div style={{width: "50%"}}>
                        <DialogTitle style={{width: "fit-content", margin: "auto"}}>{t("routingData")}</DialogTitle>
                        <Autocomplete
                            style={{margin: "auto", width: "50%"}}
                            options={countries}
                            getOptionLabel={option => option.name}
                            value={country}
                            defaultValue={country}
                            onOpen={fetchCountries}
                            onChange={handleCountryChange}
                            loading={true}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={t("country")}
                                    variant="outlined"
                                    margin="dense"
                                    value={country}
                                    defaultValue={country}
                                    inputProps={{
                                        ...params.inputProps,
                                        autoComplete: 'off', // disable autocomplete and autofill
                                    }}
                                />
                            )}
                        />
                        <Autocomplete
                            style={{margin: "auto", width: "50%"}}
                            options={administrativeLevel1List}
                            getOptionLabel={option => option.name}
                            disabled={!country}
                            value={administrativeLevel1}
                            defaultValue={administrativeLevel1}
                            onOpen={fetchAdministrativeLevel1}
                            noOptionsText={t("noResults")}
                            onChange={handleAdministrativeLevel1Change}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={t("administrativeLevel1")}
                                    variant="outlined"
                                    margin="dense"
                                    value={administrativeLevel1}
                                    defaultValue={administrativeLevel1}
                                    inputProps={{
                                        ...params.inputProps,
                                        autoComplete: 'off', // disable autocomplete and autofill
                                    }}
                                />
                            )}
                        />
                        <Autocomplete
                            style={{margin: "auto", width: "50%"}}
                            options={administrativeLevel2List}
                            getOptionLabel={option => option.name}
                            disabled={!administrativeLevel1}
                            value={administrativeLevel2}
                            defaultValue={administrativeLevel2}
                            onOpen={fetchAdministrativeLevel2}
                            noOptionsText={t("noResults")}
                            onChange={handleAdministrativeLevel2Change}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={t("administrativeLevel2")}
                                    variant="outlined"
                                    margin="dense"
                                    value={administrativeLevel2}
                                    defaultValue={administrativeLevel2}
                                    inputProps={{
                                        ...params.inputProps,
                                        autoComplete: 'off', // disable autocomplete and autofill
                                    }}
                                />
                            )}
                        />
                        <Autocomplete
                            style={{margin: "auto", width: "50%"}}
                            options={administrativeLevel3List}
                            getOptionLabel={option => option.name}
                            disabled={!administrativeLevel2}
                            value={administrativeLevel3}
                            defaultValue={administrativeLevel3}
                            onOpen={fetchAdministrativeLevel3}
                            noOptionsText={t("noResults")}
                            onChange={handleAdministrativeLevel3Change}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={t("administrativeLevel3")}
                                    variant="outlined"
                                    margin="dense"
                                    value={administrativeLevel3}
                                    defaultValue={administrativeLevel3}
                                    inputProps={{
                                        ...params.inputProps,
                                        autoComplete: 'off', // disable autocomplete and autofill
                                    }}
                                />
                            )}
                        />
                        <Autocomplete
                            style={{margin: "auto", width: "50%"}}
                            options={cities}
                            getOptionLabel={option => option.name}
                            disabled={!administrativeLevel3}
                            value={city}
                            defaultValue={city}
                            onOpen={fetchCities}
                            noOptionsText={t("noResults")}
                            onChange={handleCityChange}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={t("city")}
                                    variant="outlined"
                                    margin="dense"
                                    value={city}
                                    defaultValue={city}
                                    inputProps={{
                                        ...params.inputProps,
                                        autoComplete: 'off', // disable autocomplete and autofill
                                    }}
                                />
                            )}
                        />
                        <Autocomplete
                            style={{margin: "auto", width: "50%"}}
                            options={postalCodes}
                            getOptionLabel={option => option.postalCode}
                            disabled={!administrativeLevel3}
                            value={postalCode}
                            defaultValue={postalCode}
                            onOpen={fetchPostalCodes}
                            noOptionsText={t("noResults")}
                            onChange={handlePostalCodeChange}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={t("postalCode")}
                                    variant="outlined"
                                    margin="dense"
                                    value={postalCode}
                                    defaultValue={postalCode}
                                    inputProps={{
                                        ...params.inputProps,
                                        autoComplete: 'off', // disable autocomplete and autofill
                                    }}
                                />
                            )}
                        />
                        <div>
                            <DialogTitle style={{width: "fit-content", margin: "auto"}}>{t("gates")}</DialogTitle>
                            <Autocomplete
                                style={{margin: "auto", width: "50%"}}
                                options={gates}
                                disableCloseOnSelect={true}
                                getOptionLabel={option => option.number}
                                disabled={!sorter}
                                value={selectedGates}
                                multiple
                                defaultValue={selectedGates}
                                onOpen={fetchGates}
                                noOptionsText={t("noResults")}
                                onChange={handleGatesChange}
                                isOptionEqualToValue={(option, value) => option.number === value.number}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label={t("gates")}
                                        variant="outlined"
                                        margin="dense"
                                        error={!sorter}
                                        helperText={!sorter ?
                                            <Typography
                                                variant={"caption"}>{t("selectSorterFirst")}</Typography> : null}
                                    />
                                )}
                            />
                        </div>
                    </div>
                </div>

                <div style={{display: "flex"}}>
                    <Button onClick={() => save(false)} startIcon={<SaveOutlined/>} style={{
                        margin: "auto",
                        marginBlock: "1%",
                        backgroundColor: theme.palette.primary.main,
                        color: "white"
                    }}>{t("save")}</Button>
                </div>
                <div style={{display: "flex"}}>
                    <Button onClick={saveAndClose} startIcon={<SaveOutlined/>} endIcon={<CloseOutlined/>} style={{
                        margin: "auto",
                        marginBlock: "1%",
                        backgroundColor: theme.palette.primary.main,
                        color: "white"
                    }}>{t("saveAndClose")}</Button>
                </div>

            </Paper>
        }
    </div>


}