import Notification from "../resources/Notification";
import * as React from "react";
import {useEffect, useState} from "react";
import {
    Autocomplete,
    Button,
    CircularProgress,
    DialogTitle,
    FormControl, InputLabel, MenuItem,
    Paper,
    Select,
    TextField
} from "@mui/material";
import {ArrowBack, Save} from "@mui/icons-material";
import {useTranslation} from "react-i18next";
import {useNavigate, useParams} from "react-router-dom";
import SortingModeService from "./SortingModeService";
import {init} from "i18next";


export default function SortingModeForm(props) {

    const [loading, setLoading] = useState(false);
    const [saving, setSaving] = useState(false);

    const [notificationMessage, setNotificationMessage] = useState('');
    const [notificationSeverity, setNotificationSeverity] = useState('');
    const [openNotification, setOpenNotification] = useState(false);

    const [name, setName] = useState(null);
    const [warehouseCode, setWarehouseCode] = useState(null);
    const [eventCode, setEventCode] = useState(null);
    const [enabled, setEnabled] = useState(true);

    const [initialData, setInitialData] = useState({});

    const {id} = useParams();


    const {t} = useTranslation();
    const navigate = useNavigate();

    function closeNotification() {
        setOpenNotification(false);
    }

    function handleChangeEventCode(event) {
        if (event.target.value === '' ||
            /^[0-9\b]+$/.test(event.target.value) &&
            event.target.value.length < 3) {
            setEventCode(event.target.value);
        }
    }

    function handleChangeWarehouseCode(event){
        if(event.target.value.length < 4){
            setWarehouseCode(event.target.value.toUpperCase());
        }
    }

    function goBack() {

        navigate('/sorting-modes');

    }

    async function fetchData() {
        if (id) {
            setLoading(true);

            await new SortingModeService().getSortingModeById(id)
                .then(result => {
                    setInitialData(result);

                    setName(result.name);
                    setWarehouseCode(result.warehouseCode);
                    setEventCode(result.eventCode);
                    setEnabled(result.enabled);

                    setLoading(false)
                })
                .catch(error => {
                    error.response.json().then(response => {
                        setNotificationMessage(response.message);
                        setNotificationSeverity('error');
                        setOpenNotification(true);

                        setLoading(false);
                    })
                })
        }
    }

    useEffect(() => {
        fetchData();
    }, []);

    async function saveChanges() {

        setSaving(true);

        let input = {};
        input.name = name;
        input.warehouseCode = warehouseCode;
        input.eventCode = eventCode;
        input.enabled = enabled;

        await new SortingModeService().saveSortingMode(id, input)
            .then(result => {
                setNotificationMessage(t("saveSuccessful"));
                setNotificationSeverity('success');
                setOpenNotification(true);

                setTimeout(()=>{
                    navigate('/sorting-modes');
                    setSaving(false);
                }, 2000);

            })
            .catch(error => {
                error.response.json().then(response => {
                    setNotificationMessage(response.message);
                    setNotificationSeverity('error');
                    setOpenNotification(true);

                    setSaving(false);
                })
            })


    }

    return <div>
        <Notification
            message={notificationMessage}
            open={openNotification}
            onClose={closeNotification}
            severity={notificationSeverity}
            key={notificationSeverity}
        />
        {loading ?
            <Paper>
                <div style={{display: "flex", marginBlock: "10%", paddingBlock: "10%"}}>
                    <CircularProgress size={50} style={{margin: "auto"}}/>
                </div>
            </Paper>
            :
            <Paper>
                <div style={{display: "flex"}}>
                    <Button onClick={goBack} variant="contained" startIcon={<ArrowBack/>} style={{
                        backgroundColor: "#cecece",
                        color: "black",
                        margin: "2%"
                    }}>{t("goBack")}</Button>
                </div>
                <div>
                    <div>
                        <DialogTitle style={{width: "fit-content", margin: "auto"}}>{t("sortingMode")}</DialogTitle>
                    </div>
                    <div style={{width: "100%", display: "flex", marginBlock: "2%"}}>
                        <FormControl style={{width: "15%", margin: "auto"}}>
                            <Select
                                value={enabled}
                                onChange={event => {
                                    setEnabled(event.target.value)
                                }
                                }
                            >

                                <MenuItem value={true}>{t("enabled")}</MenuItem>
                                <MenuItem value={false}>{t("notEnabled")}</MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                    <div style={{width: "100%", display: "flex", marginBlock: "2%"}}>
                        <TextField
                            style={{margin: "auto", width: "20%"}}
                            id="name"
                            label={t("name")}
                            value={name}
                            onChange={(event) => {
                                setName(event.target.value);
                            }}
                        />
                    </div>
                    <div style={{width: "100%", display: "flex", marginBlock: "2%"}}>
                        <TextField
                            style={{margin: "auto", width: "10%"}}
                            id="warehouseCode"
                            label={t("warehouseCode")}
                            value={warehouseCode}
                            onChange={handleChangeWarehouseCode}
                        />
                    </div>
                    <div style={{width: "100%", display: "flex", marginBlock: "2%"}}>
                        <TextField
                            style={{margin: "auto", width: "10%"}}
                            id="eventCode"
                            label={t("eventCode")}
                            value={eventCode}
                            onChange={handleChangeEventCode}
                        />
                    </div>
                    <div style={{width: "100%", display: "flex", marginBlock: "2%"}}>
                        <Button disabled={initialData.name === name &&
                            initialData.eventCode === eventCode &&
                            initialData.warehouseCode === warehouseCode &&
                            initialData.enabled === enabled || saving} style={{margin: "auto", marginBottom: "2%"}}
                                variant={"contained"} onClick={saveChanges} startIcon={saving? <CircularProgress size={20}/> : <Save/>}>{t("save")}</Button>
                    </div>
                </div>
            </Paper>
        }
    </div>


}