import UserService from "../users/UserService";
import fetch from "unfetch";
import {checkStatus} from "../resources/ApiUtils";

const userService = new UserService();

export default class SortingModeService{

    getSortingModes(params) {

        const apiKey = userService.getApiKey();

        let queryString = ``;

        if (params) {

            const keys = Object.keys(params);
            for (let i = 0; i < keys.length; i++) {
                const key = keys[i];
                const value = params[key];

                let filterString = key.toString();
                if (queryString.length > 0) {
                    queryString += `&`;
                }
                queryString += `${filterString}=${value}`;
            }
        }

        const requestOptions = {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        };

        let pathVariable = `/api/v1/sorting-modes`;
        if (queryString.length > 0) {
            pathVariable += `?` + queryString
        }
        return fetch(pathVariable, requestOptions)
            .then(checkStatus)
            .then(response => response.json());
    }

    getSortingModeById(id) {

        const apiKey = userService.getApiKey();

        const requestOptions = {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        };

        let pathVariable = `/api/v1/sorting-modes/${id}`;

        return fetch(pathVariable, requestOptions)
            .then(checkStatus)
            .then(response => response.json());
    }


    saveSortingMode(id, input){

        const apiKey = userService.getApiKey();

        const requestOptions = {
            method: id? 'PUT' : 'POST',
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            body: JSON.stringify(input)
        };

        let pathVariable = `/api/v1/sorting-modes`;

        if(id){
            pathVariable += `/${id}`;
        }

        return fetch(pathVariable, requestOptions)
            .then(checkStatus);

    }


    deleteSortingMode(id){

        const apiKey = userService.getApiKey();

        const requestOptions = {
            method: 'DELETE',
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        };

        let pathVariable = `/api/v1/sorting-modes/${id}`;

        return fetch(pathVariable, requestOptions)
            .then(checkStatus)

    }







}