import * as React from 'react';
import {styled, ThemeProvider} from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Container from '@mui/material/Container';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import {ListItem, ListItemIcon, ListItemText} from "@mui/material";
import {Home, RouteOutlined, SwapVert} from "@mui/icons-material";
import {NavLink, Route, Routes} from "react-router-dom";
import RoutingRulesPage from "../routing_rules/RoutingRulesPage";
import HomePage from "./HomePage";
import theme from "../resources/theme";
import RoutingRuleForm from "../routing_rules/RoutingRuleForm";
import {useTranslation} from "react-i18next";
import SortingModesPage from "../sorting_modes/SortingModesPage";
import SortingModeForm from "../sorting_modes/SortingModeForm";

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({theme, open}) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const Drawer = styled(MuiDrawer, {shouldForwardProp: (prop) => prop !== 'open'})(
    ({theme, open}) => ({
        '& .MuiDrawer-paper': {
            position: 'relative',
            whiteSpace: 'nowrap',
            width: drawerWidth,
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
            boxSizing: 'border-box',
            ...(!open && {
                overflowX: 'hidden',
                transition: theme.transitions.create('width', {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.leavingScreen,
                }),
                width: theme.spacing(7),
                [theme.breakpoints.up('sm')]: {
                    width: theme.spacing(9),
                },
            }),
        },
    }),
);



function DashboardContent() {
    const [open, setOpen] = React.useState(true);

    const {t} = useTranslation();

    const toggleDrawer = () => {
        setOpen(!open);
    };

    return (
        <ThemeProvider theme={theme}>
            <Box sx={{display: 'flex'}}>
                <CssBaseline/>
                <AppBar position="absolute" open={open}>
                    <Toolbar
                        sx={{
                            pr: '24px', // keep right padding when drawer closed
                        }}
                    >
                        <IconButton
                            edge="start"
                            color="inherit"
                            aria-label="open drawer"
                            onClick={toggleDrawer}
                            sx={{
                                marginRight: '36px',
                                ...(open && {display: 'none'}),
                            }}
                        >
                            <MenuIcon/>
                        </IconButton>
                        <Typography
                            component="h1"
                            variant="h6"
                            color="inherit"
                            noWrap
                            sx={{flexGrow: 1}}
                        >
                            {"BRIDGE"}
                        </Typography>
                        {/*<IconButton color="inherit">*/}
                        {/*    <Badge badgeContent={4} color="secondary">*/}
                        {/*        <NotificationsIcon />*/}
                        {/*    </Badge>*/}
                        {/*</IconButton>*/}
                    </Toolbar>
                </AppBar>
                <Drawer variant="permanent" open={open}>
                    <Toolbar
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'flex-end',
                            px: [1],
                        }}
                    >
                        <IconButton onClick={toggleDrawer}>
                            <ChevronLeftIcon/>
                        </IconButton>
                    </Toolbar>
                    <Divider/>
                    <List component="nav">
                        <ListItem component={NavLink} to={'/sorting-modes'}>
                            <ListItemIcon>
                                <SwapVert/>
                            </ListItemIcon>
                            <ListItemText primary={t("sortingModes")}/>
                        </ListItem>
                        <ListItem component={NavLink} to={'/routing-rules'}>
                            <ListItemIcon>
                                <RouteOutlined/>
                            </ListItemIcon>
                            <ListItemText primary={t("routingRules")}/>
                        </ListItem>
                    </List>
                </Drawer>
                <Box
                    component="main"
                    sx={{
                        backgroundColor: (theme) =>
                            theme.palette.mode === 'light'
                                ? theme.palette.grey[100]
                                : theme.palette.grey[900],
                        flexGrow: 1,
                        height: '100vh',
                        overflow: 'auto',
                    }}
                >
                    <Toolbar/>
                    <Container maxWidth="100%" sx={{mt: 4, mb: 4}}>
                        <Routes>
                            <Route path='routing-rules/new' element={<RoutingRuleForm />} />
                            <Route path="routing-rules">
                                <Route path=":id" element={<RoutingRuleForm />} />
                            </Route>
                            <Route index path='routing-rules' element={<RoutingRulesPage />} />
                            <Route path='sorting-modes' element={<SortingModesPage />} />
                            <Route path='sorting-modes/new' element={<SortingModeForm />} />
                            <Route path="sorting-modes">
                                <Route path=":id" element={<SortingModeForm />} />
                            </Route>
                            {/*<Route index element={<HomePage/>}/>*/}
                        </Routes>
                    </Container>
                </Box>
            </Box>
        </ThemeProvider>
    );
}

export default function Dashboard() {

    return <DashboardContent/>;
}