import {Button, Card, CircularProgress, Dialog, TableCell, TableRow} from "@mui/material";
import {Delete, Edit, Save, Warning} from "@mui/icons-material";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {useState} from "react";
import Typography from "@mui/material/Typography";
import * as React from "react";
import SortingModeService from "./SortingModeService";


export default function SortingModeItem(props){

    const [openWarning, setOpenWarning] = useState(false);
    const [loadingDelete, setLoadingDelete] = useState(false);

    const {t} = useTranslation();

    const navigate = useNavigate();
    function goToEditPage(){
        navigate(`/sorting-modes/${props.item.id}`);
    }

    async function confirmDelete() {

        setLoadingDelete(true);

        await new SortingModeService().deleteSortingMode(props.item.id)
            .then(result => {
                props.onDeleteSuccess();
            })
            .catch(error => {
                error.response.json().then(response => {
                    props.onDeleteFail(response.message);
                    setLoadingDelete(false);
                })
            })

    }

    return <TableRow>
    <TableCell>
        <Typography variant={"subtitle2"} style={{color: props.item.enabled? "green" : "red"}}>{props.item.enabled? t("enabled") : t("notEnabled")}</Typography>
    </TableCell>
    <TableCell>{props.item.id}</TableCell>
    <TableCell>{props.item.name}</TableCell>
    <TableCell>{props.item.warehouseCode}</TableCell>
    <TableCell>{props.item.eventCode}</TableCell>
    <TableCell>
        <Button onClick={goToEditPage} variant="contained" startIcon={<Edit/>}>{t("update")}</Button>
    </TableCell>
    <TableCell>
        <Button color="error" onClick={()=>setOpenWarning(true)} variant="contained" startIcon={<Delete/>}>{t("delete")}</Button>
    </TableCell>
        <Dialog open={openWarning} onClose={()=>setOpenWarning(false)}>
            <Card style={{borderColor: "red", border: "6px solid red"}}>
                <div style={{width: "fit-content", margin: "auto", marginBlock: "3%"}}>
                    <Warning color={"error"}/>
                </div>
                <div style={{width: "fit-content", margin: "auto", marginBlock: "2%"}}>
                   <Typography variant={"button"}>{"Attenzione: eliminazione definitiva piano " + props.item.id + " - " + props.item.name}</Typography>
                </div>
                <div style={{margin: "5%"}}>
                    <Typography variant={"subtitle2"}>
                        Eliminando il piano verrano eliminati in modo IRREVERSIBILE anche i dati relativi
                        le regole di routing, le richieste e i routing dei colli già effettuati associati al piano.
                        Procedere comunque?
                    </Typography>
                </div>
                <div style={{width: "100%", display: "flex", marginBlock: "2%"}}>
                    <Button style={{margin: "auto", marginBottom: "2%"}}
                            disabled={loadingDelete}
                            onClick={confirmDelete}
                            variant={"contained"} color={"error"} startIcon={loadingDelete? <CircularProgress color={"error"} size={20}/> : <Delete/>}>{t("confirmDelete")}</Button>
                </div>
            </Card>
        </Dialog>
</TableRow>



}