import {Route, Routes, useNavigate} from "react-router-dom"
import './App.css';
import LoginPage from "./login/LoginPage";
import DashboardPage from "./dashboard/DashboardPage";
import * as React from "react";
import {useEffect} from "react";
import UserService from "./users/UserService";
import RoutingRulesPage from "./routing_rules/RoutingRulesPage";
import RoutingRuleForm from "./routing_rules/RoutingRuleForm";
import PageNotFound from "./resources/PageNotFound";
import SortingModesPage from "./sorting_modes/SortingModesPage";
import SortingModeForm from "./sorting_modes/SortingModeForm";


function App() {

    const userService = new UserService();

    const shouldRedirect = !userService.isLoggedIn();

    const navigate = useNavigate();

    useEffect(() => {
        if (shouldRedirect) {
            navigate('/login');
        }
    }, [shouldRedirect]);


    return (

            <Routes>
                <Route path="/login" element={<LoginPage/>}/>
                <Route element={<DashboardPage/>}>
                    <Route index element={<DashboardPage/>}/>
                    <Route path="/routing-rules" >
                        <Route index element={<RoutingRulesPage/>}/>
                        <Route path="new" element={<RoutingRuleForm/>}/>
                        <Route path=":id" element={<RoutingRuleForm/>}/>
                    </Route>
                    <Route path="/sorting-modes" >
                        <Route index element={<SortingModesPage/>}/>
                        <Route path="new" element={<SortingModeForm/>}/>
                        <Route path=":id" element={<SortingModeForm/>}/>
                    </Route>
                </Route>
                <Route path={"*"} element={<PageNotFound/>}/>
            </Routes>

    );
}

export default App;
