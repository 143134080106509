import {Button, Fade, IconButton, Menu, MenuItem, Modal, Paper, TableCell, TableRow, Typography} from "@mui/material";
import {
    CancelOutlined,
    CheckCircleOutlined,
    DeleteForeverOutlined,
    EditAttributes,
    MenuOutlined
} from "@mui/icons-material";
import RoutingRulesService from "./RoutingRulesService";
import {useState} from "react";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";


export default function RoutingRuleItem(props){

    const [anchorEl, setAnchorEl] = useState(null);
    const [openConfirmDelete, setOpenConfirmDelete] = useState(false);

    const {t} = useTranslation();
    const navigate = useNavigate();

    function handleMenuClick(event) {
        setAnchorEl(event.currentTarget);
    }

    function handleMenuClose() {
        setAnchorEl(null);
    }

    function goToEditPage(){
        navigate(`/routing-rules/${props.item.id}`);
    }

    async function deleteRoutingRule() {

        await new RoutingRulesService().deleteRoutingRule(props.item.id)
            .then(result => {
                props.popMessage("operationSuccessful");
            })
            .catch(error => {
                error.response.json().then( message =>{
                    props.popMessage(message);
                })
            })
    }


    return <TableRow>
        <TableCell>
            <Typography variant={"subtitle2"}>{props.item.id}</Typography>
        </TableCell>
        <TableCell>
            <Typography variant={"subtitle2"}>{props.item.sorter || "*"}</Typography>
            <Typography variant={"subtitle2"}>{props.item.sortingMode || "*"}</Typography>
        </TableCell>
        <TableCell>
            <Typography variant={"subtitle2"}>{props.item.accountCorporateCode || "*"}</Typography>
            <Typography variant={"subtitle2"} >{props.item.contractCode || "*"}</Typography>
        </TableCell>
        <TableCell>
            <Typography variant={"subtitle2"}>{props.item.warehouseCode || "*"}</Typography>
            <Typography variant={"subtitle2"}>{props.item.zoneCode || "*"}</Typography>
        </TableCell>
        <TableCell>
            <Typography variant={"subtitle2"}>{props.item.country || "*"}</Typography>
        </TableCell>
        <TableCell>
            <Typography variant={"subtitle2"}>{props.item.administrativeLevel1 || "*"}</Typography>
        </TableCell>
        <TableCell>
            <Typography variant={"subtitle2"}>{props.item.administrativeLevel2 || "*"}</Typography>
        </TableCell>
        <TableCell>
            <Typography variant={"subtitle2"}>{props.item.administrativeLevel3 || "*"}</Typography>
        </TableCell>
        <TableCell>
            <Typography variant={"subtitle2"}>{props.item.city || "*"}</Typography>
        </TableCell>
        <TableCell>
            <Typography variant={"subtitle2"}>{props.item.postalCode || "*"}</Typography>
        </TableCell>
        <TableCell>
            <Typography variant={"subtitle2"}>{props.item.minWeight? props.item.minWeight.toFixed(2) + " Kg" : "*"}</Typography>
            <Typography variant={"subtitle2"}>{props.item.maxWeight? props.item.maxWeight.toFixed(2) + " Kg" : "*"}</Typography>
        </TableCell>
        <TableCell>
            <Typography variant={"subtitle2"}>{props.item.minVolume? props.item.minVolume.toFixed(2) + " Kg" : "*"}</Typography>
            <Typography variant={"subtitle2"}>{props.item.maxVolume? props.item.maxVolume.toFixed(2) + " Kg" : "*"}</Typography>
        </TableCell>
        <TableCell>
            {props.item.gates.map(gate => {
                return <Typography variant={"subtitle2"}>{gate}</Typography>
            })}
        </TableCell>
        <TableCell>
            <IconButton onClick={handleMenuClick}>
                <MenuOutlined/>
            </IconButton>
            {/*<Button aria-controls="roles-menu" aria-haspopup="true" onClick={handleMenuClick}>*/}
            {/*    <MenuIcon/>*/}
            {/*</Button>*/}
            <Menu
                id="user-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
            >
                <MenuItem onClick={()=>setOpenConfirmDelete(true)}><DeleteForeverOutlined/>&nbsp;{t("deleteRoutingRule")}</MenuItem>
                <MenuItem onClick={goToEditPage}><EditAttributes/>&nbsp;{t("editRoutingRule")}</MenuItem>

            </Menu>
        </TableCell>
        <Modal open={openConfirmDelete}
               onClose={() => setOpenConfirmDelete(false)}
               closeAfterTransition
               BackdropProps={{
                   timeout: 500,
               }}
               disableEnforceFocus
        >
            <Fade in={openConfirmDelete}>
                <Paper variant={"outlined"} elevation={2} style={{
                    width: "30%",
                    marginInline: "auto",
                    marginTop: "20%",
                    height: "20%",
                    overflow: "hidden",
                }}>
                    <div style={{width: "fit-content", margin: "auto", marginTop: "10%", display: "flex"}}>
                        <Typography variant={"button"}>{t("confirmDeleteRoutingRuleDialog") + props.item.id + "?"}</Typography>
                    </div>
                    <div style={{
                        display: "flex",
                        alignItems: 'center',
                        justifyContent: 'center',
                        marginBlock: "4%",
                        paddingBottom: "2%"
                    }}>
                        <Button
                            style={{
                                marginInline: "4%",
                                width: "25%",
                                backgroundColor: "lightgray",
                                color:"black"
                            }}
                            endIcon={<CancelOutlined/>}
                            onClick={() => setOpenConfirmDelete(false)}
                            variant={"contained"}>
                            {t("cancel")}
                        </Button>
                        <Button
                            style={{
                                marginInline: "4%",
                                width: "25%"
                            }}
                            endIcon={<CheckCircleOutlined/>}
                            onClick={deleteRoutingRule}
                            color="primary"
                            variant={"contained"}>
                            {t("confirm")}
                        </Button>
                    </div>
                </Paper>
            </Fade>

        </Modal>
    </TableRow>



}