import {useEffect, useState} from "react";
import {Avatar, Button, Container, TextField, Typography} from "@mui/material";
import {LockOutlined} from "@mui/icons-material";
import UserService from "../users/UserService";
import theme from "../resources/theme";
import {useNavigate} from "react-router-dom";
import Notification from "../resources/Notification";
import * as React from "react";
import {useTranslation} from "react-i18next";

export default function LoginPage(props) {

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    const userService = new UserService();

    const navigate = useNavigate();

    const [notificationMessage, setNotificationMessage] = useState('');
    const [notificationSeverity, setNotificationSeverity] = useState('');
    const [openNotification, setOpenNotification] = useState(false);

    const {t} = useTranslation();

    function onFormSubmit() {

        userService.login(username, password)
            .then(result => {
                navigate('/routing-rules');
            })
            .catch(error => {
                error.response.json().then(response => {
                    setNotificationMessage(response.message);
                    setNotificationSeverity('error');
                    setOpenNotification(true);
                })
            })
    }

    function closeNotification() {
        setOpenNotification(false);
    }

    useEffect(()=>{

        // if (userService.isLoggedIn()) {
        //     navigate('/');
        // }
    }, [])

    function onUsernameChange(event) {
        setUsername(event.target.value)
    }

    function onPasswordChange(event) {
        setPassword(event.target.value)
    }


    return <Container component={"main"} maxWidth={'xs'}>
        <Notification
            message={notificationMessage}
            open={openNotification}
            onClose={closeNotification}
            severity={notificationSeverity}
            key={notificationSeverity}
        />
        <div style={{
            marginTop: theme.spacing(10),
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyItems: "center"
        }}>
            <Avatar style={{
                margin: theme.spacing(1),
                backgroundColor: theme.palette.primary.main
            }}>
                <LockOutlined/>
            </Avatar>
            <Typography component="h1" variant="button">
                {t("reservedArea")}
            </Typography>
                <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="username"
                    label={t("username")}
                    name="username"
                    autoComplete={("username")}
                    value={username}
                    onChange={onUsernameChange}
                    autoFocus
                />
                <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    label={t("password")}
                    type="password"
                    id="password"
                    value={password}
                    onChange={onPasswordChange}
                    autoComplete={("password")}
                />
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    onClick={onFormSubmit}
                >
                    {t("signIn")}
                </Button>
        </div>


    </Container>


}