import UserService from "../users/UserService";
import fetch from "unfetch";
import {checkStatus} from "../resources/ApiUtils";


const userService = new UserService();

export default class GeoService {


    getCountries() {

        const apiKey = userService.getApiKey();

        let queryString = ``;

        const requestOptions = {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        };

        let pathVariable = `/api/v1/countries`;
        if (queryString.length > 0) {
            pathVariable += `?` + queryString
        }
        return fetch(pathVariable, requestOptions)
            .then(checkStatus)
            .then(response => response.json());
    }


    getAdministrativeLevel1(params) {

        const apiKey = userService.getApiKey();

        let queryString = ``;

        if (params) {

            const keys = Object.keys(params);
            for (let i = 0; i < keys.length; i++) {
                const key = keys[i];
                const value = params[key];

                let filterString = key.toString();
                if (queryString.length > 0) {
                    queryString += `&`;
                }
                queryString += `${filterString}=${value}`;
            }
        }

        const requestOptions = {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        };

        let pathVariable = `/api/v1/administrative-level-1`;
        if (queryString.length > 0) {
            pathVariable += `?` + queryString
        }
        return fetch(pathVariable, requestOptions)
            .then(checkStatus)
            .then(response => response.json());
    }

    getAdministrativeLevel2(params) {

        const apiKey = userService.getApiKey();

        let queryString = ``;

        if (params) {

            const keys = Object.keys(params);
            for (let i = 0; i < keys.length; i++) {
                const key = keys[i];
                const value = params[key];

                let filterString = key.toString();
                if (queryString.length > 0) {
                    queryString += `&`;
                }
                queryString += `${filterString}=${value}`;
            }
        }

        const requestOptions = {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        };

        let pathVariable = `/api/v1/administrative-level-2`;
        if (queryString.length > 0) {
            pathVariable += `?` + queryString
        }
        return fetch(pathVariable, requestOptions)
            .then(checkStatus)
            .then(response => response.json());
    }

    getAdministrativeLevel3(params) {

        const apiKey = userService.getApiKey();

        let queryString = ``;

        if (params) {

            const keys = Object.keys(params);
            for (let i = 0; i < keys.length; i++) {
                const key = keys[i];
                const value = params[key];

                let filterString = key.toString();
                if (queryString.length > 0) {
                    queryString += `&`;
                }
                queryString += `${filterString}=${value}`;
            }
        }

        const requestOptions = {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        };

        let pathVariable = `/api/v1/administrative-level-3`;
        if (queryString.length > 0) {
            pathVariable += `?` + queryString
        }
        return fetch(pathVariable, requestOptions)
            .then(checkStatus)
            .then(response => response.json());
    }

    getCities(params) {

        const apiKey = userService.getApiKey();

        let queryString = ``;

        if (params) {

            const keys = Object.keys(params);
            for (let i = 0; i < keys.length; i++) {
                const key = keys[i];
                const value = params[key];

                let filterString = key.toString();
                if (queryString.length > 0) {
                    queryString += `&`;
                }
                queryString += `${filterString}=${value}`;
            }
        }

        const requestOptions = {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        };

        let pathVariable = `/api/v1/cities`;
        if (queryString.length > 0) {
            pathVariable += `?` + queryString
        }
        return fetch(pathVariable, requestOptions)
            .then(checkStatus)
            .then(response => response.json());
    }

    getPostalCodes(params) {

        const apiKey = userService.getApiKey();

        let queryString = ``;

        if (params) {

            const keys = Object.keys(params);
            for (let i = 0; i < keys.length; i++) {
                const key = keys[i];
                const value = params[key];

                let filterString = key.toString();
                if (queryString.length > 0) {
                    queryString += `&`;
                }
                queryString += `${filterString}=${value}`;
            }
        }

        const requestOptions = {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + apiKey,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        };

        let pathVariable = `/api/v1/postal-codes`;
        if (queryString.length > 0) {
            pathVariable += `?` + queryString
        }
        return fetch(pathVariable, requestOptions)
            .then(checkStatus)
            .then(response => response.json());
    }


}