import {Alert, Slide, Snackbar, Typography} from "@mui/material";
import {useState} from "react";

function Transition(props) {
    return <Slide {...props} direction="left" />;
}


export default function Notification(props){

    return <div style={{borderRadius: 15}}>
        <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'right'}}
            open={props.open}
            onClose={props.onClose}
            TransitionComponent={Transition}
            autoHideDuration={props.autoHideDuration? props.autoHideDuration : 5000}
        >
            <Alert onClose={props.onClose} severity={props.severity? props.severity : "info"}  sx={{ width: '100%' }}>
                <Typography variant={"subtitle2"}>{props.message}</Typography>
            </Alert>
        </Snackbar>
    </div>
}