import * as React from "react";
import {useEffect, useState} from "react";
import SortingModeService from "./SortingModeService";
import Typography from "@mui/material/Typography";
import {Button, Card, Table, TableCell, TableHead, TableRow} from "@mui/material";
import {useTranslation} from "react-i18next";
import {LibraryAdd} from "@mui/icons-material";
import SortingModeItem from "./SortingModeItem";
import Notification from "../resources/Notification";
import {useNavigate} from "react-router-dom";


export default function SortingModesPage(){

    const [sortingModes, setSortingModes] = useState([]);

    const [notificationMessage, setNotificationMessage] = useState('');
    const [notificationSeverity, setNotificationSeverity] = useState('');
    const [openNotification, setOpenNotification] = useState(false);

    const [refresh, setRefresh] = useState(false);

    const {t} = useTranslation();

    const navigate = useNavigate();

    function closeNotification() {
        setOpenNotification(false);
    }
    async function fetchSortingModes(){

        await new SortingModeService().getSortingModes()
            .then(result => {
                setSortingModes(result);
            })
            .catch(error => {
                error.response.json().then(response => {
                    setNotificationMessage(response.message);
                    setNotificationSeverity('error');
                    setOpenNotification(true);
                })
            })
    }

    function onDeleteSuccess(){
        setRefresh(!refresh);
    }

    function onDeleteFail(message){
        setNotificationMessage(message);
        setNotificationSeverity('error');
        setOpenNotification(true);
    }

    function goToForm(){
        navigate("/sorting-modes/new");
    }

    useEffect(() => {
        fetchSortingModes();
    }, [refresh]);


    return <div>
        <Notification
            message={notificationMessage}
            open={openNotification}
            onClose={closeNotification}
            severity={notificationSeverity}
            key={notificationSeverity}
        />
        <Card style={{width: "100%", maxWidth:"960px", margin: "auto"}}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>
                            <Typography variant={"subtitle2"}>{t("enabled")}</Typography>
                        </TableCell>
                        <TableCell>
                            <Typography variant={"subtitle2"}>{t("ID")}</Typography>
                        </TableCell>
                        <TableCell>
                            <Typography variant={"subtitle2"}>{t("name")}</Typography>
                        </TableCell>
                        <TableCell>
                            <Typography variant={"subtitle2"}>{t("warehouseCode")}</Typography>
                        </TableCell>
                        <TableCell>
                            <Typography variant={"subtitle2"}>{t("eventCode")}</Typography>
                        </TableCell>
                        <TableCell/>
                        <TableCell/>
                    </TableRow>
                </TableHead>
                {sortingModes.map(item => {
                    return <SortingModeItem item={item} onDeleteSuccess={onDeleteSuccess} onDeleteFail={onDeleteFail}/>
                })}
            </Table>

        </Card>
        <div style={{width: "100%", display: "flex", marginBlock: "4%"}}>
            <Button style={{margin: "auto", marginBottom: "2%"}}
                    variant={"contained"} onClick={goToForm} startIcon={<LibraryAdd/>}>{t("createNewSortingMode")}</Button>
        </div>
    </div>

}