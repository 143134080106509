import * as React from "react";
import {useEffect, useState} from "react";
import RoutingRulesService from "./RoutingRulesService";
import {
    Autocomplete,
    Button,
    Card, CircularProgress, DialogTitle, Divider,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Typography
} from "@mui/material";
import {useTranslation} from "react-i18next";
import RoutingRuleItem from "./RoutingRuleItem";
import theme from "../resources/theme";
import Notification from "../resources/Notification";
import GeoService from "../geo/GeoService";
import {Search} from "@mui/icons-material";
import {useNavigate} from "react-router-dom";
import SortingModeService from "../sorting_modes/SortingModeService";


export default function RoutingRulesPage(props) {

    const [routingRules, setRoutingRules] = useState([]);
    const [pageSize, setPageSize] = useState(50);
    const [pageNumber, setPageNumber] = useState(0);
    const [countries, setCountries] = useState([]);
    const [administrativeLevel1List, setAdministrativeLevel1List] = useState([]);
    const [administrativeLevel2List, setAdministrativeLevel2List] = useState([]);
    const [administrativeLevel3List, setAdministrativeLevel3List] = useState([]);
    const [cities, setCities] = useState([]);
    const [postalCodes, setPostalCodes] = useState([]);
    const [hamlets, setHamlets] = useState([]);
    const [country, setCountry] = useState(null);
    const [adminLevel1, setAdminLevel1] = useState(null);
    const [adminLevel2, setAdminLevel2] = useState(null);
    const [adminLevel3, setAdminLevel3] = useState(null);
    const [city, setCity] = useState(null);
    const [postalCode, setPostalCode] = useState(null);
    const [hamlet, setHamlet] = useState(null);

    const [sortingModes, setSortingModes] = useState([]);
    const [sortingMode, setSortingMode] = useState(null);

    const [accountCorporateCode, setAccountCorporateCode] = useState('');
    const [contractCode, setContractCode] = useState('');
    const [warehouseCode, setWarehouseCode] = useState('');
    const [zoneCode, setZoneCode] = useState('');

    const [notificationMessage, setNotificationMessage] = useState('');
    const [notificationSeverity, setNotificationSeverity] = useState('');
    const [openNotification, setOpenNotification] = useState(false);

    const [refresh, setRefresh] = useState(false);
    const [loading, setLoading] = useState(false);


    const {t} = useTranslation();
    const navigate = useNavigate();

    function closeNotification() {
        setOpenNotification(false);
    }

    function goToRoutingRuleForm() {
        navigate('/routing-rules/new');
    }

    async function fetchRules() {

        setLoading(true);

        let params = {};

        if (contractCode !== '') {
            params.contractCode = contractCode;
        }
        if (accountCorporateCode !== '') {
            params.accountCorporateCode = accountCorporateCode;
        }
        if (warehouseCode !== '') {
            params.warehouseCode = warehouseCode;
        }
        if (zoneCode !== '') {
            params.zoneCode = zoneCode;
        }

        if(sortingMode){
            params.sortingMode = sortingMode.id;
        }

        if (country) {
            params.country = country.id;
        }

        if (adminLevel1) {
            params.administrativeLevel1 = adminLevel1.id;
        }
        if (adminLevel2) {
            params.administrativeLevel2 = adminLevel2.id;
        }
        if (adminLevel3) {
            params.administrativeLevel3 = adminLevel3.id;
        }
        if (city) {
            params.city = city.id;
        }
        if (postalCode) {
            params.postalCode = postalCode.id;
        }

        await new RoutingRulesService().getRoutingRulesByParams(params)
            .then(response => {
                setRoutingRules(response.records);
            })
            .catch(error => {
                error.response.json().then(response => {
                    setNotificationMessage(response.message);
                    setNotificationSeverity('error');
                    setOpenNotification(true);
                })
            })

        setLoading(false);

    }

    async function popMessage(message) {
        if (message === "operationSuccessful") {
            setNotificationSeverity("success");
        } else {
            setNotificationSeverity("error");
        }
        setNotificationMessage(t(message))
        setOpenNotification(true);

        await fetchRules();
    }

    async function fetchCountries() {

        await new GeoService().getCountries()
            .then(result => {
                setCountries(result);
            })
            .catch(error => {

            })

    }

    async function fetchAdministrativeLevel1() {

        let params = {};

        if (country) {
            params.countryID = country.id;
        }

        await new GeoService().getAdministrativeLevel1(params)
            .then(response => {
                setAdministrativeLevel1List(response);
            })
            .catch(error => {

            })

    }

    async function fetchAdministrativeLevel2() {

        let params = {};

        if (adminLevel1) {
            params.administrativeLevel1ID = adminLevel1.id;
        }

        await new GeoService().getAdministrativeLevel2(params)
            .then(response => {
                setAdministrativeLevel2List(response);
            })
            .catch(error => {

            })

    }

    async function fetchAdministrativeLevel3() {

        let params = {};

        if (adminLevel2) {
            params.administrativeLevel2ID = adminLevel2.id;
        }

        await new GeoService().getAdministrativeLevel3(params)
            .then(response => {
                setAdministrativeLevel3List(response);
            })
            .catch(error => {

            })

    }


    async function fetchCities() {

        let params = {};

        if (adminLevel3) {
            params.administrativeLevel3ID = adminLevel3.id;
        }

        await new GeoService().getCities(params)
            .then(response => {
                setCities(response);
            })
            .catch(error => {

            })

    }

    async function fetchPostalCodes() {

        let params = {};

        if (city) {
            params.cityID = city.id;
        }

        await new GeoService().getPostalCodes(params)
            .then(response => {
                setPostalCodes(response);
            })
            .catch(error => {

            })

    }

    async function fetchSortingModes(){

        await new SortingModeService().getSortingModes()
            .then(result => {
                setSortingModes(result)
            })
            .catch(error => {

            })


    }


    function handleCountryChange(event, country) {

        setCountry(country);
        setAdminLevel1(null);
        setAdminLevel2(null);
        setAdminLevel3(null);
        setCity(null);
        setPostalCode(null);
        setHamlet(null);

    }

    function handleAdministrativeLevel1Change(event, administrativeLevel1) {

        setAdminLevel1(administrativeLevel1);
        setAdminLevel2(null);
        setAdminLevel3(null);
        setCity(null);
        setPostalCode(null);
        setHamlet(null);

    }

    function handleAdministrativeLevel2Change(event, administrativeLevel2) {


        setAdminLevel2(administrativeLevel2);
        setAdminLevel3(null);
        setCity(null);
        setPostalCode(null);
        setHamlet(null);

    }

    function handleAdministrativeLevel3Change(event, administrativeLevel3) {


        setAdminLevel3(administrativeLevel3);
        setCity(null);
        setPostalCode(null);
        setHamlet(null);

    }

    function handleCityChange(event, city) {

        setCity(city);
        setPostalCode(null);
        setHamlet(null);

    }

    function handlePostalCodeChange(event, postalCode) {

        setPostalCode(postalCode);
        setHamlet(null);

    }

    function handleSortingModeChange(event, sortingMode){

        setSortingMode(sortingMode);

    }

    function search() {
        setRefresh(!refresh)
    }


    useEffect(() => {

        fetchRules();

    }, [refresh])


    return <div>
        <Notification
            message={notificationMessage}
            open={openNotification}
            onClose={closeNotification}
            severity={notificationSeverity}
            key={notificationSeverity}
        />
        <Card style={{marginBlock: "3%"}}>
            <div style={{display: "flex", marginBlock: "2%"}}>
                <Autocomplete
                    style={{margin: "auto", width: "20%"}}
                    options={sortingModes}
                    getOptionLabel={option => option.name}
                    value={sortingMode}
                    defaultValue={sortingMode}
                    onOpen={fetchSortingModes}
                    onChange={handleSortingModeChange}
                    loading={true}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={t("sortingMode")}
                            variant="outlined"
                            margin="dense"
                            value={sortingMode}
                            defaultValue={sortingMode}
                            inputProps={{
                                ...params.inputProps,
                                autoComplete: 'off', // disable autocomplete and autofill
                            }}
                        />
                    )}
                />
            </div>
            <div style={{display: "flex", marginBlock: "2%"}}>
                <TextField
                    style={{margin: "auto"}}
                    id="account"
                    label={t("accountCorporateCode")}
                    value={accountCorporateCode}
                    onChange={(event) => {
                        setAccountCorporateCode(event.target.value);
                    }}
                />
                <TextField
                    style={{margin: "auto"}}
                    id="contract"
                    label={t("contractCode")}
                    value={contractCode}
                    onChange={(event) => {
                        setContractCode(event.target.value);
                    }}
                />
                <TextField
                    style={{margin: "auto"}}
                    id="warehouse"
                    label={t("warehouseCode")}
                    value={warehouseCode}
                    onChange={(event) => {
                        setWarehouseCode(event.target.value);
                    }}
                />
                <TextField
                    style={{margin: "auto"}}
                    id="zone"
                    label={t("zoneCode")}
                    value={zoneCode}
                    onChange={(event) => {
                        setZoneCode(event.target.value);
                    }}
                />
            </div>
            <div style={{display: "flex", marginBlock: "2%"}}>
                 <DialogTitle style={{margin: "auto"}}>{t("routingData")}</DialogTitle>
            </div>
            <div style={{display: "flex", marginBlock: "2%"}}>
                <Autocomplete
                    style={{margin: "auto", width: "20%"}}
                    options={countries}
                    getOptionLabel={option => option.name}
                    value={country}
                    defaultValue={country}
                    onOpen={fetchCountries}
                    onChange={handleCountryChange}
                    loading={true}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={t("country")}
                            variant="outlined"
                            margin="dense"
                            value={country}
                            defaultValue={country}
                            inputProps={{
                                ...params.inputProps,
                                autoComplete: 'off', // disable autocomplete and autofill
                            }}
                        />
                    )}
                />
                <Autocomplete
                    style={{margin: "auto", width: "20%"}}
                    options={administrativeLevel1List}
                    getOptionLabel={option => option.name}
                    disabled={!country}
                    value={adminLevel1}
                    defaultValue={adminLevel1}
                    onOpen={fetchAdministrativeLevel1}
                    noOptionsText={t("noResults")}
                    onChange={handleAdministrativeLevel1Change}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={t("administrativeLevel1")}
                            variant="outlined"
                            margin="dense"
                            value={adminLevel1}
                            defaultValue={adminLevel1}
                            inputProps={{
                                ...params.inputProps,
                                autoComplete: 'off', // disable autocomplete and autofill
                            }}
                        />
                    )}
                />
                <Autocomplete
                    style={{margin: "auto", width: "20%"}}
                    options={administrativeLevel2List}
                    getOptionLabel={option => option.name}
                    disabled={!adminLevel1}
                    value={adminLevel2}
                    defaultValue={adminLevel2}
                    onOpen={fetchAdministrativeLevel2}
                    noOptionsText={t("noResults")}
                    onChange={handleAdministrativeLevel2Change}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={t("administrativeLevel2")}
                            variant="outlined"
                            margin="dense"
                            value={adminLevel2}
                            defaultValue={adminLevel2}
                            inputProps={{
                                ...params.inputProps,
                                autoComplete: 'off', // disable autocomplete and autofill
                            }}
                        />
                    )}
                />
            </div>
            <div style={{display: "flex", marginBlock: "2%"}}>
                <Autocomplete
                    style={{margin: "auto", width: "20%"}}
                    options={administrativeLevel3List}
                    getOptionLabel={option => option.name}
                    disabled={!adminLevel2}
                    value={adminLevel3}
                    defaultValue={adminLevel3}
                    onOpen={fetchAdministrativeLevel3}
                    noOptionsText={t("noResults")}
                    onChange={handleAdministrativeLevel3Change}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={t("administrativeLevel3")}
                            variant="outlined"
                            margin="dense"
                            value={adminLevel3}
                            defaultValue={adminLevel3}
                            inputProps={{
                                ...params.inputProps,
                                autoComplete: 'off', // disable autocomplete and autofill
                            }}
                        />
                    )}
                />
                <Autocomplete
                    style={{margin: "auto", width: "20%"}}
                    options={cities}
                    getOptionLabel={option => option.name}
                    disabled={!adminLevel3}
                    value={city}
                    defaultValue={city}
                    onOpen={fetchCities}
                    noOptionsText={t("noResults")}
                    onChange={handleCityChange}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={t("city")}
                            variant="outlined"
                            margin="dense"
                            value={city}
                            defaultValue={city}
                            inputProps={{
                                ...params.inputProps,
                                autoComplete: 'off', // disable autocomplete and autofill
                            }}
                        />
                    )}
                />
                <Autocomplete
                    style={{margin: "auto", width: "20%"}}
                    options={postalCodes}
                    getOptionLabel={option => option.postalCode}
                    disabled={!city}
                    value={postalCode}
                    defaultValue={postalCode}
                    onOpen={fetchPostalCodes}
                    noOptionsText={t("noResults")}
                    onChange={handlePostalCodeChange}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={t("postalCode")}
                            variant="outlined"
                            margin="dense"
                            value={postalCode}
                            defaultValue={postalCode}
                            inputProps={{
                                ...params.inputProps,
                                autoComplete: 'off', // disable autocomplete and autofill
                            }}
                        />
                    )}
                />
            </div>
            <div style={{display: "flex"}}>
                <Button onClick={search} disabled={loading} variant={"contained"} startIcon={<Search/>} style={{
                    margin: "auto",
                    marginBottom: "2%"
                }}>{t("search")}</Button>
            </div>

        </Card>

        <div style={{display: "flex", marginBottom: "3%"}}>
            <Button onClick={goToRoutingRuleForm} variant={"contained"} disabled={loading} style={{
                margin: "auto"
            }}>{t("createNewRoutingRule")}</Button>
        </div>

        {loading ?
            <div style={{display: "flex", marginBlock: "10%"}}>
                <CircularProgress size={50} style={{margin: "auto"}}/>
            </div>

            :
            <Paper>
                <TableContainer>
                    <Table stickyHeader>

                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    <Typography variant={"subtitle2"}>{t("id")}</Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography variant={"subtitle2"}>{t("sorter")}</Typography>
                                    <Typography variant={"subtitle2"}>{t("sortingMode")}</Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography variant={"subtitle2"}>{t("accountCorporateCode")}</Typography>
                                    <Typography variant={"subtitle2"}>{t("contractCode")}</Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography variant={"subtitle2"}>{t("warehouseCode")}</Typography>
                                    <Typography variant={"subtitle2"}>{t("zoneCode")}</Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography variant={"subtitle2"}>{t("country")}</Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography variant={"subtitle2"}>{t("administrativeLevel1")}</Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography variant={"subtitle2"}>{t("administrativeLevel2")}</Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography variant={"subtitle2"}>{t("administrativeLevel3")}</Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography variant={"subtitle2"}>{t("city")}</Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography variant={"subtitle2"}>{t("postalCode")}</Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography variant={"subtitle2"}>{t("minWeight")}</Typography>
                                    <Typography variant={"subtitle2"}>{t("maxWeight")}</Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography variant={"subtitle2"}>{t("minVolume")}</Typography>
                                    <Typography variant={"subtitle2"}>{t("maxVolume")}</Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography variant={"subtitle2"}>{t("gates")}</Typography>
                                </TableCell>
                                <TableCell/>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {routingRules.map((value, index) => {
                                return <RoutingRuleItem item={value} popMessage={popMessage}/>
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
        }
    </div>


}