import {createTheme} from "@mui/material";
import {grey} from "@mui/material/colors";

const theme = createTheme({
    components: {
        MuiTableRow: {
            defaultProps: {
                style: {
                    borderCollapse: "separate"
                }
            },
        },
        MuiCard: {
            variants: [
                {
                    props: {variant: 'miniCard'},
                    style: {
                        width: "20%",
                        margin: "auto",
                        marginBottom: "3%",
                        borderRadius: 10
                    }
                }
            ]
        },
        MuiTypography: {
            defaultProps: {
                style: {
                    fontFamily: 'Roboto',
                }
            },
            variants: [
                {
                    props: {variant: "tableItem"},
                    style: {
                        fontSize: 14,
                        fontFamily: 'Roboto'
                    }
                }
            ]
        }
    },
    palette: {
        primary: {
            error: "#ff4141",
            success: "#22ff49",
            main: "#2e77ff"
        },
        mode: 'light',
        default: {
            main: grey[300],
            dark: grey[400]
        }
    }
})

export default theme